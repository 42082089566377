<template>
  <v-container
    id="portal-management"
    tag="section"
  >
    <base-material-card
      :title="'Managing Portal ' + portalID + ' | ' + portalName.toUpperCase()"
      color="indigo"
      inline
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-format-letter-case" title="Portal Text and Configuration">
            <v-row justify="center" align="center">
              <v-col sm="6" md="3" lg="2">
                <v-card color="#4056F4" class="px-4 py-4 card-shadow border-radius-xl h-100">
                  <div class="d-flex flex-column justify-center text-center h-100">
                    <a href="javascript:;" class="text-decoration-none" @click="editPortal">
                      <v-icon
                        large
                        class="mb-3"
                        aria-hidden="true"
                        color="white"
                      >mdi-pencil</v-icon>
                      <h5 class="text-h5" style="color: #fff;">Edit Portal Info</h5>
                    </a>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-cached" title="Portal Cache Refreshes">
            <v-row justify="center" align="center">
              <v-col sm="6" md="3" lg="2">
                <v-card color="#4056F4" class="px-4 py-4 card-shadow border-radius-xl h-100">
                  <div class="d-flex flex-column justify-center text-center h-100">
                    <a href="javascript:;" class="text-decoration-none" @click="refreshDataCache">
                      <v-icon
                        v-if="!refreshDataLoading"
                        large
                        class="mb-3"
                        aria-hidden="true"
                        color="white"
                      >mdi-database-refresh</v-icon>
                      <v-progress-circular
                        v-else
                        :size="50"
                        :width="7"
                        color="#60DEDE"
                        indeterminate
                      ></v-progress-circular>
                      <h5 class="text-h5" style="color: #fff;">Refresh Video & Listing Cache</h5>
                    </a>
                  </div>
                </v-card>
              </v-col>
              <v-col sm="6" md="3" lg="2">
                <v-card color="#4056F4" class="px-4 py-4 card-shadow border-radius-xl h-100">
                  <div class="d-flex flex-column justify-center text-center h-100">
                    <a href="javascript:;" class="text-decoration-none" @click="refreshPortalCache">
                      <v-icon
                        v-if="!refreshPortalLoading"
                        large
                        class="mb-3"
                        aria-hidden="true"
                        color="white"
                      >mdi-cog-refresh</v-icon>
                      <v-progress-circular
                        v-else
                        :size="50"
                        :width="7"
                        color="#60DEDE"
                        indeterminate
                      ></v-progress-circular>
                      <h5 class="text-h5" style="color: #fff;">Refresh Portal Settings Cache</h5>
                    </a>
                  </div>
                </v-card>
              </v-col>
              <v-col sm="6" md="3" lg="2">
                <v-card color="#4056F4" class="px-4 py-4 card-shadow border-radius-xl h-100">
                  <div class="d-flex flex-column justify-center text-center h-100">
                    <a href="javascript:;" class="text-decoration-none" @click="refreshAllCache">
                      <v-icon
                        v-if="!refreshAllLoading"
                        large
                        class="mb-3"
                        aria-hidden="true"
                        color="white"
                      >mdi-refresh</v-icon>
                      <v-progress-circular
                        v-else
                        :size="50"
                        :width="7"
                        color="#60DEDE"
                        indeterminate
                      ></v-progress-circular>
                      <h5 class="text-h5" style="color: #fff;">Refresh All Cache</h5>
                    </a>
                  </div>
                </v-card>
              </v-col>
              <v-col sm="6" md="3" lg="2">
                <v-card color="#4056F4" class="px-4 py-4 card-shadow border-radius-xl h-100">
                  <div class="d-flex flex-column justify-center text-center h-100">
                    <a href="javascript:;" class="text-decoration-none" @click="reindexElasticSearch">
                      <v-icon
                        v-if="!reindexElasticLoading"
                        large
                        class="mb-3"
                        aria-hidden="true"
                        color="white"
                      >mdi-cloud-search</v-icon>
                      <v-progress-circular
                        v-else
                        :size="50"
                        :width="7"
                        color="#60DEDE"
                        indeterminate
                      ></v-progress-circular>
                      <h5 class="text-h5" style="color: #fff;">Re-Index Elastic Search</h5>
                    </a>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-material-card title="Category Mapping" icon="mdi-swap-horizontal-variant">
            <v-row>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="categoryMappings"
                  :items-per-page="10"
                  class="elevation-1"
                  :loading="catmapLoading"
                >
                  <template v-slot:top>
                    <v-row justify="center" align="center">
                      <v-col cols="12" sm="6" md="3" class="text-sm-center ml-2">
                        <v-dialog v-model="createCatMapDialog" :retain-focus="false" max-width="800px" scrollable>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              class="mt-4 mr-0"
                            >
                              Add Category Map
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">Create A New Category Mapping</span>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="5">
                                  <v-select
                                    class="mx-auto"
                                    v-model="sourceCategory"
                                    :items="categories"
                                    item-text="tagName"
                                    item-value="id"
                                    label="Select a source category."
                                    outlined
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-icon style="margin-left: 30px; margin-top: 10px;" large>mdi-shuffle-variant</v-icon>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-select
                                    class="mx-auto"
                                    v-model="targetCategory"
                                    :items="categories"
                                    item-text="tagName"
                                    item-value="id"
                                    label="Select a target category."
                                    outlined
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" v-on:click.stop="createCatMapDialog = false">Nevermind</v-btn>
                              <v-btn color="green darken-1" v-on:click.stop="createCatMap()">Create</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click.stop="deleteConfirm(item)"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog
                  :retain-focus="false"
                  v-model="deleteConfirmDialog"
                  max-width="500"
                >
                  <v-card>
                    <v-card-title class="headline">Are you sure?</v-card-title>
                    <v-card-text>
                      This mapping will be deleted.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        v-on:click.stop="deleteConfirmDialog = false"
                      >
                        No, don't delete
                      </v-btn>
                      <v-btn
                        color="red darken-1"
                        v-on:click.stop="deleteCatMap"
                      >
                      Yes, delete mapping
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <base-material-card :title="portalName + ' | Top Picks'" icon="mdi-movie-open-star">
            <v-card>
              <v-toolbar
                flat
                color="primary"
                dark
              >
                <v-toolbar-title>Select Top Picks for Listings, Videos and Resources</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="updateTopPicks">Update Top Picks</v-btn>
              </v-toolbar>
              <v-tabs>
                <v-tab>
                  <v-icon left>
                    mdi-clipboard-outline
                  </v-icon>
                  Listings
                </v-tab>
                <v-tab>
                  <v-icon left>
                    mdi-play-box-multiple-outline
                  </v-icon>
                  Videos
                </v-tab>
                <v-tab>
                  <v-icon left>
                    mdi-folder-outline
                  </v-icon>
                  Resources
                </v-tab>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        ref="listingTopPicks"
                        :headers="topPickHeaders"
                        :items="topPickListings"
                        item-key="uniqueID"
                        class="elevation-1"
                        sort-by="order"
                      >
                      <template v-slot:top>
                          <v-row justify="center" align="center">
                            <v-col cols="12" sm="6" class="text-sm-center ml-2">
                              <v-switch v-model="allowListingDrag" label="Re Order Listing Top Picks"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="text-sm-center ml-2">
                              <v-btn
                                color="primary"
                                class="mt-4 mr-0"
                                v-on:click.stop="openTopPickListing"
                              >
                                Add Listing
                              </v-btn>
                              <v-dialog v-model="addTopPickListingDialog" :retain-focus="false" scrollable persistent>
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">Add a Listing to Top Picks</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <ListingSelect @fullListingEmit="getAddedListings"/>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" v-on:click.stop="addTopPickListingDialog = false">Done</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        <template #body="props">
                          <draggable
                            :list="props.items"
                            tag="tbody"
                            :disabled="!allowListingDrag"
                            :move="onMoveListing"
                            @end="onDropListing"
                          >
                            <data-table-row-handler
                              v-for="(item, index) in props.items"
                              :key="index"
                              :item="item"
                              :headers="topPickHeaders"
                            >
                              <template #item.actions="{ item }">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      @click.stop="removeTopPickListing(item)"
                                      color="error"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Remove
                                  </span>
                                </v-tooltip>
                              </template>
                            </data-table-row-handler>
                          </draggable>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        ref="resourceTopPicks"
                        :headers="topPickHeaders"
                        :items="topPickVideos"
                        item-key="uniqueID"
                        class="elevation-1"
                        sort-by="order"
                      >
                      <template v-slot:top>
                          <v-row justify="center" align="center">
                            <v-col cols="12" sm="6" class="text-sm-center ml-2">
                              <v-switch v-model="allowVideoDrag" label="Re Order Video Top Picks"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="text-sm-center ml-2">
                              <v-btn
                                color="primary"
                                class="mt-4 mr-0"
                                v-on:click.stop="openTopPickVideo"
                              >
                                Add Videos
                              </v-btn>
                              <v-dialog v-model="addTopPickVideoDialog" :retain-focus="false" scrollable persistent>
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">Add a Video to Top Picks</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <VideoSelect :portalID="portalID" @fullVideoEmit="getAddedVideo"/>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" v-on:click.stop="addTopPickVideoDialog = false">Done</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        <template #body="props">
                          <draggable
                            :list="props.items"
                            tag="tbody"
                            :disabled="!allowVideoDrag"
                            :move="onMoveVideo"
                            @end="onDropVideo"
                          >
                            <data-table-row-handler
                              v-for="(item, index) in props.items"
                              :key="index"
                              :item="item"
                              :headers="topPickHeaders"
                            >
                              <template #item.actions="{ item }">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      @click.stop="removeTopPickVideo(item)"
                                      color="error"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Remove
                                  </span>
                                </v-tooltip>
                              </template>
                            </data-table-row-handler>
                          </draggable>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        ref="resourceTopPicks"
                        :headers="topPickHeaders"
                        :items="topPickResources"
                        item-key="uniqueID"
                        class="elevation-1"
                        sort-by="order"
                      >
                      <template v-slot:top>
                          <v-row justify="center" align="center">
                            <v-col cols="12" sm="6" class="text-sm-center ml-2">
                              <v-switch v-model="allowResourceDrag" label="Re Order Resource Top Picks"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="text-sm-center ml-2">
                              <v-btn
                                color="primary"
                                class="mt-4 mr-0"
                                v-on:click.stop="openTopPickResource"
                              >
                                Add Resource
                              </v-btn>
                              <v-dialog v-model="addTopPickResourceDialog" :retain-focus="false" scrollable persistent>
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">Add a Resource to Top Picks</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12">
                                        <ResourceSelect @fullResourceEmit="getAddedResource"/>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="green darken-1" v-on:click.stop="addTopPickResourceDialog = false">Done</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </template>
                        <template #body="props">
                          <draggable
                            :list="props.items"
                            tag="tbody"
                            :disabled="!allowResourceDrag"
                            :move="onMoveResource"
                            @end="onDropResource"
                          >
                            <data-table-row-handler
                              v-for="(item, index) in props.items"
                              :key="index"
                              :item="item"
                              :headers="topPickHeaders"
                            >
                              <template #item.actions="{ item }">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      @click.stop="removeTopPickResource(item)"
                                      color="error"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>Remove
                                  </span>
                                </v-tooltip>
                              </template>
                            </data-table-row-handler>
                          </draggable>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
      <v-dialog scrollable :retain-focus="false" v-model="editDialog" max-width="800">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Portal - {{editPortalObject.name}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <h3>Page Headers</h3>
              <v-row>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.homePageHeader" label="Home Page Header"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.videoHeader" label="Video Page Header"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.supportHeader" label="Support Page Header"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.resourceHeader" label="Resource Page Header"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h3>Page Tag Lines</h3>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.videoTagLine" label="Video Tag Line"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.supportTagLine" label="Support Tag Line"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.resourceTagLine" label="Resource Tag Line"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h3>Hero Tag Lines</h3>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.topTagLine" label="Top Tag Line"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.mainTagLine" label="Main Tag Line"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.bottomTagLine" label="Bottom Tag Line"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h3>Top Pick Descriptors</h3>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.topPickVideos" label="Top Pick Videos"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.topPickListings" label="Top Pick Listings"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.topPickResources" label="Top Pick Resources"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h3>External Link Details</h3>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.externalLinkText" label="External Link Text"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.externalLinkURL" label="External Link URL"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <h3>Call To Action</h3>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.ctaLink" label="CTA Link"></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field outlined v-model="editPortalObject.text.ctaTitle" label="CTA Title"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined v-model="editPortalObject.text.ctaImage" label="CTA Image"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" v-on:click.stop="editDialog = false">Cancel</v-btn>
            <v-btn color="green darken-1" v-on:click.stop="updatePortal()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snack" bottom :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular
          v-if="snackColor === 'info'"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>

import ResetCache from '@/Services/Portals/ResetCache'
import GetPortals from '@/Services/Portals/GetPortals'
import UpdatePortal from '@/Services/Portals/UpdatePortal'
import CategoryMapping from '@/Services/Portals/CategoryMappings'
import GetCategories from '@/Services/TagManagement'
import TopPicks from '@/Services/Portals/TopPicks'
import ListingSelect from '@/views/dashboard/tables/ListingSelect'
import VideoSelect from '@/views/dashboard/tables/SingleVideoSelect'
import ResourceSelect from '@/views/dashboard/tables/ResourceSelect'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/views/dashboard/component/DataTableRowHandler'

export default {
  name: 'PortalDetails',
  props: {
    portalID: String,
    portalName: String
  },
  components: {
    ListingSelect,
    ResourceSelect,
    VideoSelect,
    draggable,
    DataTableRowHandler
  },
  data () {
    return {
      refreshDataLoading: false,
      refreshPortalLoading: false,
      refreshAllLoading: false,
      reindexElasticLoading: false,
      editPortalLoading: false,
      snack: false,
      snackColor: '',
      snackText: null,
      snackTimeout: null,
      editDialog: false,
      editPortalObject: {
        name: '',
        text: {
          id: null,
          videoHeader: '',
          supportHeader: '',
          homePageHeader: '',
          resourceHeader: '',
          videoTagLine: '',
          supportTagLine: '',
          resourceTagLine: '',
          mainTagLine: '',
          topTagLine: '',
          bottomTagLine: '',
          topPickVideos: '',
          topPickListings: '',
          topPickResources: '',
          externalLinkText: '',
          externalLinkURL: '',
          ctaLink: '',
          ctaImage: '',
          ctaTitle: ''
        }
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Source Category',
          sortable: false,
          value: 'sourceCategory'
        },
        {
          text: 'Target Category',
          sortable: false,
          value: 'targetCategory'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      categories: [],
      categoryMappings: [],
      createCatMapDialog: false,
      sourceCategory: null,
      targetCategory: null,
      catmapLoading: false,
      deleteConfirmDialog: false,
      deleteMappingID: null,
      topPicks: [],
      topPickListings: [],
      topPickVideos: [],
      topPickResources: [],
      topPickHeaders: [
        {
          text: 'ID',
          align: 'start',
          value: 'uniqueID'
        },
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          sortable: true
        },
        {
          text: 'Type',
          align: 'center',
          value: 'type',
          sortable: false
        },
        {
          text: 'Order',
          align: 'center',
          value: 'order',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],
      addTopPickListingDialog: false,
      addTopPickVideoDialog: false,
      addTopPickResourceDialog: false,
      allowListingDrag: false,
      allowResourceDrag: false,
      allowVideoDrag: false
    }
  },
  created () {
    this.getCategoryMappings()
    this.getTopPicks()
  },
  methods: {
    async getCategoryMappings () {
      this.catmapLoading = true
      this.snackLoading('Fetching Category Mappings')
      await CategoryMapping.getCategoryMappings(this.portalID)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryMappings', response.data)
            this.snackSuccess('Category Mappings Refreshed.')
            this.catmapLoading = false
          } else {
            this.catmapLoading = false
            this.snackError('Could not fetch category mappings. Please try again later.')
          }
        })
      await GetCategories.getCats()
        .then((response) => {
          this.$set(this, 'categories', response.data)
        })
    },
    async createCatMap () {
      const catMap = {
        sourceCategory: this.sourceCategory,
        targetCategory: this.targetCategory
      }
      await CategoryMapping.createCatMap(this.portalID, catMap)
        .then((response) => {
          if (response.status === 200) {
            this.createCatMapDialog = false
            this.getCategoryMappings()
            this.snackSuccess('Category Mapping Created.')
          } else {
            this.createCatMapDialog = false
            this.snackError('Could not create category mapping. Please try again later.')
          }
        })
    },
    deleteConfirm (item) {
      this.deleteMappingID = item.id
      this.deleteConfirmDialog = true
    },
    async deleteCatMap () {
      await CategoryMapping.deleteCatMap(this.portalID, this.deleteMappingID)
        .then((response) => {
          if (response.status === 200) {
            this.deleteConfirmDialog = false
            this.getCategoryMappings()
            this.snackSuccess('Category Mapping Deleted.')
          } else {
            this.deleteConfirmDialog = false
            this.snackError('Could not delete category mapping. Please try again later.')
          }
        })
    },
    async refreshDataCache () {
      const resetType = 1
      this.refreshDataLoading = true
      this.snackLoading('Refreshing Data Cache')
      await ResetCache.resetData(this.portalID, resetType)
        .then((response) => {
          if (response.status === 200) {
            this.snackSuccess('Data Cache Refreshed')
            this.refreshDataLoading = false
          } else {
            this.refreshDataLoading = false
            this.snackError('Could not refresh cache. Please try again later.')
          }
        })
    },
    async refreshPortalCache () {
      const resetType = 2
      this.refreshPortalLoading = true
      this.snackLoading('Refreshing Portal Settings')
      await ResetCache.resetData(this.portalID, resetType)
        .then((response) => {
          if (response.status === 200) {
            this.snackSuccess('Portal Settings Refreshed')
            this.refreshPortalLoading = false
          } else {
            this.refreshAllLoading = false
            this.snackError('Could not refresh cache. Please try again later.')
          }
        })
    },
    async refreshAllCache () {
      const resetType = 3
      this.refreshAllLoading = true
      this.snackLoading('Refreshing All Caches')
      await ResetCache.resetData(this.portalID, resetType)
        .then((response) => {
          if (response.status === 200) {
            this.snackSuccess('Caches Refreshed')
            this.refreshAllLoading = false
          } else {
            this.refreshAllLoading = false
            this.snackError('Could not refresh cache. Please try again later.')
          }
        })
    },
    async reindexElasticSearch () {
      this.reindexElasticLoading = true
      this.snackLoading('Re-Indexing Elastic Search')
      await ResetCache.reindexElasticSearch(this.portalID)
        .then((response) => {
          if (response.status === 200) {
            this.snackSuccess('Elastic Search Re-Indexed')
            this.reindexElasticLoading = false
          } else {
            this.reindexElasticLoading = false
            this.snackError('Could not re-index elastic search. Please try again later.')
          }
        })
    },
    async editPortal () {
      this.editPortalLoading = true
      await GetPortals.getPortalDetails(this.portalID)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'editPortalObject', response.data)
            this.editDialog = true
          } else if (response.status === 204) {
            this.snackError('No data returned')
          } else {
            this.snackError('An error occurred. Try again later.')
          }
        })
    },
    async updatePortal () {
      this.snackLoading('Loading ...')
      this.isLoading = true
      await UpdatePortal.updatePortal(this.editPortalObject.id, this.editPortalObject.text)
        .then((res) => {
          if (res.status === 200) {
            this.snackSuccess('Portal Updated')
            this.isLoading = false
            this.editDialog = false
          } else {
            this.isLoading = false
            this.editDialog = false
            this.snackError('An error occured. Try again later.')
          }
        })
    },
    async updateTopPicks () {
      this.topPicks = {
        listings: this.topPickListings,
        videos: this.topPickVideos,
        resources: this.topPickResources
      }
      await TopPicks.setTopPicks(this.portalID, this.topPicks).then((res) => {
        if (res.status === 200) {
          this.snackSuccess('Top Picks Updated')
        } else {
          this.snackError('Could not update Top Picks. Please try again later.')
        }
      })
    },
    async getTopPicks () {
      await TopPicks.getTopPicks(this.portalID).then((res) => {
        if (res.status === 200) {
          this.$set(this, 'topPicks', res.data)
          if (this.topPicks.listings.length !== 0) {
            this.$set(this, 'topPickListings', this.topPicks.listings)
          }
          if (this.topPicks.videos.length !== 0) {
            this.$set(this, 'topPickVideos', this.topPicks.videos)
          }
          if (this.topPicks.resources.length !== 0) {
            this.$set(this, 'topPickResources', this.topPicks.resources)
          }
        } else {
          this.snackError('Could not retrieve Top Picks')
        }
      })
    },
    openTopPickListing () {
      this.addTopPickListingDialog = true
    },
    openTopPickResource () {
      this.addTopPickResourceDialog = true
    },
    openTopPickVideo () {
      this.addTopPickVideoDialog = true
    },
    getAddedListings (listings) {
      const errorArr = []
      listings.forEach((element) => {
        const matchedIndex = this.topPickListings.findIndex(i => i.uniqueID === element.uniqueID)
        if (matchedIndex === -1) {
          let length = this.topPickListings.length + 1
          const listingPos = length++
          this.topPickListings.push(
            {
              uniqueID: element.uniqueID,
              name: element.name,
              type: 1,
              order: listingPos
            }
          )
        } else {
          errorArr.push(element.name)
        }
      })
      if (errorArr.length !== 0) {
        let errMsg = 'The following listings are already in top picks: '
        errorArr.forEach((element) => {
          errMsg = errMsg + '`' + element + '`' + ' '
        })
        this.snackError(errMsg)
      }
    },
    removeTopPickListing (item) {
      const deleteIndex = this.topPickListings.indexOf(item)
      this.topPickListings.splice(deleteIndex, 1)
      let listingCount = 1
      this.topPickListings.forEach(element => {
        element.order = listingCount
        listingCount++
      })
      listingCount = null
    },
    onMoveListing (evt, originalEvent) {
      const item = evt.draggedContext.element
      if (item.locked) {
        return false
      }
      return true
    },
    onDropListing (evt, originalEvent) {
      let currIndex = evt.oldIndex
      let newIndex = evt.newIndex
      const currentItem = this.topPickListings[currIndex]
      const swappedItem = this.topPickListings[newIndex]
      currIndex = currentItem.order
      newIndex = swappedItem.order
      currentItem.order = newIndex
      swappedItem.order = currIndex
      const currItemIndex = this.topPickListings.findIndex(i => i.uniqueID === currentItem.uniqueID)
      if (~currItemIndex) {
        this.topPickListings[currItemIndex] = currentItem
      }
      const swappedItemIndex = this.topPickListings.findIndex(i => i.uniqueID === swappedItem.uniqueID)
      if (~swappedItemIndex) {
        this.topPickListings[swappedItemIndex] = swappedItem
      }
    },
    getAddedResource (resources) {
      const errorArr = []
      resources.forEach((element) => {
        const matchedIndex = this.topPickResources.findIndex(i => i.uniqueID === element.uniqueID)
        if (matchedIndex === -1) {
          let length = this.topPickResources.length + 1
          const resourcePos = length++
          this.topPickResources.push(
            {
              uniqueID: element.uniqueID,
              name: element.name,
              type: 1,
              order: resourcePos
            }
          )
        } else {
          errorArr.push(element.name)
        }
      })
      if (errorArr.length !== 0) {
        let errMsg = 'The following resources are already in top picks: '
        errorArr.forEach((element) => {
          errMsg = errMsg + '`' + element + '`' + ' '
        })
        this.snackError(errMsg)
      }
    },
    removeTopPickResource (item) {
      const deleteIndex = this.topPickResources.indexOf(item)
      this.topPickResources.splice(deleteIndex, 1)
      let resourceCount = 1
      this.topPickResources.forEach(element => {
        element.order = resourceCount
        resourceCount++
      })
      resourceCount = null
    },
    onMoveResource (evt, originalEvent) {
      const item = evt.draggedContext.element
      // const itemIdx = evt.draggedContext.futureIndex
      if (item.locked) {
        return false
      }
      return true
    },
    onDropResource (evt, originalEvent) {
      let currIndex = evt.oldIndex
      let newIndex = evt.newIndex
      const currentItem = this.topPickResources[currIndex]
      const swappedItem = this.topPickResources[newIndex]
      currIndex = currentItem.order
      newIndex = swappedItem.order
      currentItem.order = newIndex
      swappedItem.order = currIndex
      const currItemIndex = this.topPickResources.findIndex(i => i.uniqueID === currentItem.uniqueID)
      if (~currItemIndex) {
        this.topPickResources[currItemIndex] = currentItem
      }
      const swappedItemIndex = this.topPickResources.findIndex(i => i.uniqueID === swappedItem.uniqueID)
      if (~swappedItemIndex) {
        this.topPickResources[swappedItemIndex] = swappedItem
      }
    },
    getAddedVideo (videos) {
      const errorArr = []
      videos.forEach((element) => {
        const matchedIndex = this.topPickVideos.findIndex(i => i.uniqueID === element.uniqueID)
        if (matchedIndex === -1) {
          let length = this.topPickVideos.length + 1
          const videoPos = length++
          this.topPickVideos.push(
            {
              uniqueID: element.uniqueID,
              name: element.name,
              type: 1,
              order: videoPos
            }
          )
        } else {
          errorArr.push(element.name)
        }
      })
      if (errorArr.length !== 0) {
        let errMsg = 'The following videos are already in top picks: '
        errorArr.forEach((element) => {
          errMsg = errMsg + '`' + element + '`' + ' '
        })
        this.snackError(errMsg)
      }
    },
    removeTopPickVideo (item) {
      const deleteIndex = this.topPickVideos.indexOf(item)
      this.topPickVideos.splice(deleteIndex, 1)
      let videoCount = 1
      this.topPickVideos.forEach(element => {
        element.order = videoCount
        videoCount++
      })
      videoCount = null
    },
    onMoveVideo (evt, originalEvent) {
      const item = evt.draggedContext.element
      // const itemIdx = evt.draggedContext.futureIndex
      if (item.locked) {
        return false
      }
      return true
    },
    onDropVideo (evt, originalEvent) {
      let currIndex = evt.oldIndex
      let newIndex = evt.newIndex
      const currentItem = this.topPickVideos[currIndex]
      const swappedItem = this.topPickVideos[newIndex]
      currIndex = currentItem.order
      newIndex = swappedItem.order
      currentItem.order = newIndex
      swappedItem.order = currIndex
      const currItemIndex = this.topPickVideos.findIndex(i => i.uniqueID === currentItem.uniqueID)
      if (~currItemIndex) {
        this.topPickVideos[currItemIndex] = currentItem
      }
      const swappedItemIndex = this.topPickVideos.findIndex(i => i.uniqueID === swappedItem.uniqueID)
      if (~swappedItemIndex) {
        this.topPickVideos[swappedItemIndex] = swappedItem
      }
    },
    snackLoading (loadingText) {
      this.snack = true
      this.snackText = loadingText
      this.snackColor = 'info'
      this.snackTimeout = -1
    },
    snackSuccess (successText) {
      this.snack = true
      this.snackText = successText
      this.snackColor = 'success'
      this.snackTimeout = 3000
    },
    snackError (errorMessage) {
      this.snack = true
      this.snackText = errorMessage
      this.snackColor = 'error'
      this.snackTimeout = 5000
    }
  }
}
</script>

<style>

</style>
