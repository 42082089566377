<template>
  <v-container>
    <v-data-table v-model="resourcesToAdd" show-select :headers="headers" :items="resources" :disable-pagination="true" hide-default-footer item-key="uniqueID" sort-desc :loading="loading">
      <template v-slot:top>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field v-model="searchTerm" append-icon="mdi-magnify" class="ml-auto" label="Search..." hide-details single-line clearable @change="filterTable" />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select class="mx-auto" v-model="searchCat" :items="categoryOptions" item-text="tagName" item-value="id" label="Select a category." clearable v-on:change="filterTable"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select :items="pageOptions" v-model="rowsPerPage" append-icon="mdi-chevron-down" class="ml-auto" label="Rows per Page" hide-details v-on:change="filterTable">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="text-sm-center">
            <v-btn v-if="reportToggle === false" color="info" class="mt-4 mr-0" @click="toggleReport">
              90 Day Report
            </v-btn>
            <v-btn v-if="reportToggle === true" color="success" class="mt-4 mr-0" @click="toggleReport">
              90 Day Report
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" class="text-sm-center">
            <v-btn
              color="green darken-1"
              class="mt-4 mr-0"
              @click="addToGroup"
            >
              Add Resources to Group
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <h2>Sorry, we couldn't find anything that matched that search.</h2>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-btn small rounded :disabled="(prevCursor === undefined || prevCursor === '') || loading === true" color="blue darken-1" v-on:click="previousPage()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn small rounded :disabled="(nextCursor === undefined || nextCursor === '') || loading === true" color="blue darken-1" v-on:click="nextPage()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>

import resourceManagement from '@/Services/Resources/ResourceManagement'

export default {
  name: 'ResourceSelect',
  data: () => ({
    resourcesToAdd: [],
    resourcesToEmit: [],
    reportToggle: false,
    searchURL: null,
    nextCursor: null,
    prevCursor: null,
    rowsPerPage: 10,
    pageOptions: [5, 10, 20, 50, 100],
    categoryOptions: [{
      text: 'Please Select',
      value: null
    }],
    loading: true,
    headers: [{
      text: 'ID',
      align: 'start',
      value: 'uniqueID'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Published Date',
      value: 'publishedDate'
    },
    {
      text: 'Last Edited',
      value: 'lastEditedDate'
    }
    ],
    resources: [],
    searchTerm: null,
    searchCat: null
  }),
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.loading = true
      this.searchURL = null
      await resourceManagement.init(this.rowsPerPage, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.resources = []
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Could not retrieve results'
            this.snackTimeout = 5000
            this.loading = false
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
      await resourceManagement.getCat()
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'categoryOptions', response.data)
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load categories.'
            this.snackTimeout = 5000
          }
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(e)
        })
      this.searchTerm = null
      this.searchCat = null
    },
    async previousPage () {
      this.loading = true
      await resourceManagement.getPreviousPage(this.rowsPerPage, this.searchURL, this.prevCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get previous results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async nextPage () {
      this.loading = true
      await resourceManagement.getNextPage(this.rowsPerPage, this.searchURL, this.nextCursor, this.reportToggle)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to get next results'
            this.snackTimeout = 5000
          }
        })
        .catch(e => {
          this.errors.push(e)
          this.loading = false
        })
    },
    async filterTable () {
      this.loading = true
      const term = this.searchTerm
      const cat = this.searchCat
      let url
      if (this.reportToggle) {
        url = 'articles/resources/admin?count=' + this.rowsPerPage + '&report=' + this.reportToggle
      } else {
        url = 'articles/resources/admin?count=' + this.rowsPerPage
      }
      let extraParam = null
      if (term && cat) {
        extraParam = '&term=' + term + '&cat=' + cat
      } else if (term && !cat) {
        extraParam = '&term=' + term
      } else if (!term && cat) {
        extraParam = '&cat=' + cat
      }
      if (extraParam !== null) {
        url = url + extraParam
      }
      if (this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam + '&report=' + this.reportToggle
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage + '&report=' + this.reportToggle
        }
      } else if (!this.reportToggle) {
        if (extraParam !== null) {
          this.searchURL = '?count=' + this.rowsPerPage + extraParam
        } else if (extraParam === null) {
          this.searchURL = '?count=' + this.rowsPerPage
        }
      }
      await resourceManagement.filterTable(url)
        .then((response) => {
          if (response.status === 200) {
            this.$set(this, 'resources', response.data.results)
            this.$set(this, 'nextCursor', response.data.nextCursor)
            this.$set(this, 'prevCursor', response.data.previousCursor)
            this.loading = false
          } else {
            this.$set(this, 'resources', [])
            this.loading = false
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to retrieve results'
            this.snackTimeout = 5000
          }
        })
    },
    toggleReport () {
      this.reportToggle = !this.reportToggle
      this.initialize()
    },
    addToGroup () {
      this.resourcesToAdd.forEach(element => {
        this.resourcesToEmit.push(element.uniqueID)
      })
      this.$emit('fullResourceEmit', this.resourcesToAdd)
      this.$emit('resourcesAdded', this.resourcesToEmit)
      this.resourcesToEmit = []
      this.resourcesToAdd = []
    }
  }
}
</script>
